.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.home
{
 column-gap: normal;
  display:ruby-base-container;
  text-decoration: solid;
  font-weight: bolder;
  font-size: xx-large;

  
}

.App-link {
  color: #61dafb;
}
.image{
  background-attachment: fixed;
  background-image: url("https://www.shutterstock.com/image-photo/confident-smiling-female-employee-260nw-339668693.jpg");
  color: #282c34;
  background-size: cover;
  
}
.name{
  color: #61dafb;
  background-image: url("https://img.freepik.com/premium-photo/portrait-young-beautiful-businesswoman_53419-3859.jpg?size=626&ext=jpg&ga=GA1.2.1363007830.1675139317&semt=ais");
  background-size: cover;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.photo
{
  background-attachment: fixed;
  background-size: cover;
  background-image: url("https://static.wixstatic.com/media/77f5caab3f7d42d0ba0e174c88d9eac7.jpeg/v1/fill/w_2141,h_1428,al_c,q_90/77f5caab3f7d42d0ba0e174c88d9eac7.webp");
}
.employee{
  text-decoration: wavy;
  background-color: #61dafb;
  font-family: 'Courier New', Courier, monospace;
  stroke: #61dafb;
  height: 20px;
  width: 1000px;
}
.employee1
{
  
 width: 100%;
 height: 9000%;
 align-self: unset;
}
.opd{
  background-image: url("https://thumbs.dreamstime.com/b/unknown-female-doctor-medical-staff-hospital-teamwork-medicine-125068207.jpg");
  font: 1em sans-serif;
  font-display: unset;
  font-size: larger;
  background-size: cover;
  font-weight: bolder;
  font-optical-sizing: initial;
  color: black;
  font-feature-settings: "afrc";
}
 

 